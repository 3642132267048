import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Initial from './pages/Initial.jsx';
import Products from './pages/Products.jsx';
import Coupon from './pages/Coupon.jsx';
import Refund from './pages/Refund.jsx';
import Post from './pages/Post.jsx';
import { OccurrenceProvider } from './context/OccurrenceContext.jsx';
import './App.css';


function App() {
  return (
    <OccurrenceProvider>
      <Router>
        <Routes>
          <Route exact path='/:shop' element={<Initial />} />
          <Route path='/:shop/products/:name/:email' element={<Products />} /> 
          <Route path='/:shop/coupon/:name' element={<Coupon />} /> 
          <Route path='/:shop/refund/:name' element={<Refund />} />
          <Route path='/:shop/post/:name' element={<Post />} />
          </Routes>
        </Router>
    </OccurrenceProvider>
  );
}

export default App;
