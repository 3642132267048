import { useEffect, useState } from "react";
import Page from "../components/layout/Page.jsx";
import { useParams } from "react-router-dom";
import { useOccurrence } from "../context/OccurrenceContext.jsx";
import { Snackbar, CircularProgress } from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import styles from "./Post.module.css";
import config from "../config/config.js";
import AddressModal from "../components/AddressModal.jsx";
import { useNavigate } from "react-router-dom";

const Post = () => {
  const urlApi = config.api;
  const { shop } = useParams();
  const { store, occurrence } = useOccurrence();
  const [postCode, setPostCode] = useState();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [resize, setResize] = useState(false);
  const [address, setAddress] = useState({ street: "", number: "", complement: "", neighborhood: "", phone: "", zip: "", city: "", province: "" });
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let bodyHeight = ""
    if (openModal) {
      bodyHeight = document.querySelector(".modal-div")?.offsetHeight;
    } else {
    bodyHeight = document.body.offsetHeight;
    }
    window.parent.postMessage(bodyHeight, "*");
  }, [store, postCode, resize, openModal]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  useEffect(() => {
    const getInfos = async () => {
      try {
        const dataInterpreter = {
          shop,
          id: occurrence?.id,
          name: occurrence?.name,
          shipping_address: occurrence?.shipping_address,
        };
        const auxAdrress = await axios.post(`${urlApi}/interpreter`, { dataInterpreter });
        setAddress({
          street: auxAdrress.data.interpreter.address.shipping.street,
          number: auxAdrress.data.interpreter.address.shipping.number,
          complement: auxAdrress.data.interpreter.address.shipping.complement,
          neighborhood: auxAdrress.data.interpreter.address.shipping.neighborhood,
          phone: occurrence?.shipping_address?.phone,
          zip: auxAdrress.data.interpreter.address.shipping.zip,
          city: auxAdrress.data.interpreter.address.shipping.city,
          province: auxAdrress.data.interpreter.address.shipping.province,
        });
      } catch (error) {
        console.error(error);
      }
      setResize(true)
      setLoadingAddress(false)
    };
    getInfos();
  }, [shop, urlApi, loadingConfirm]);

  const createOccurrence = async () => {
    const dados = {
      ...occurrence,
      shipping_address: {
        ...occurrence.shipping_address,
        street: address.street,
        neighborhood: address.neighborhood,
        number: address.number,
        complement: address.complement,
        address1: address.street + " " + address.number,
        address2: address.complement + ", " + address.neighborhood,
        city: address.city,
        zip: address.zip,
        province_code: address.province,
      }
    };

    setLoadingConfirm(true);
    try {
      const response = await axios.post(`${urlApi}/occurrences`, dados);
      if (response.data.duplicated) {
        setError(true);
        setOpenModal(false);
        setLoading(false);
        setMessage(response.data.message);
      } else {
        setError(false);
        setOpenModal(false);
        setLoading(false);
        setPostCode(response?.data.postReverse);
      }
      setResize(true);
    } catch (error) {
      setError(true);
      setMessage(error.response?.data.message);
      console.error(error);
    }
    setLoadingConfirm(false);
  };

  const handleCopyCode = () => {
    window.navigator.clipboard.writeText(`${postCode.postCode} - CEP destino: ${postCode.zipDestination}`);
    window.parent.postMessage({ type: 'copy', text: `${postCode.postCode} - CEP destino: ${postCode.zipDestination}`}, '*');
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const reverseFreeOrWarranty = occurrence?.shipping_reverse_free || occurrence?.products?.some((product) => product.action === "Garantia");

  return (
    <>
      <div className={styles.back}>
        <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
        <p>Trocas e Devoluções</p>
      </div>
      <Page>
        {!loading ? (
          <div className={styles.box}>
            {error ? (
              <div className={styles.info}>
                <CancelOutlinedIcon style={{ color: "#D72C0D", width: "42", height: "42" }} />
                <h2>Ops, algo deu errado!</h2>
                <p>Infelizmente não foi possível processar sua solicitação.</p>
                {message && <div className={styles.message}><Icon icon="material-symbols:error" color="red" width="32" height="32" /> <p><b>{message}</b></p></div>}
              </div>
            ) : (
              <div className={styles.info}>
                <div className={styles.infoBox}>
                  <CheckCircleOutlineIcon style={{ color: "var(--green-100)", width: "42", height: "42" }} />
                  <h2>Solicitação confirmada!</h2>
                  <h4>Sua troca/devolução foi processada com sucesso!</h4>
                  {reverseFreeOrWarranty || !loading && <p>Confira abaixo o seu código de postagem para o endereço informado.</p>}
                </div>
                {reverseFreeOrWarranty ? (
                  <>
                    <div className={styles.postCode}>
                      <p>Código de Postagem</p>
                      <div className={styles.inputBox}>
                        <input className={styles.input} value={postCode ? `${postCode.postCode} - CEP destino: ${postCode.zipDestination}` : "Código não gerado"} />
                        <div className={styles.buttonCopy} onClick={handleCopyCode}>
                          <ContentCopyOutlinedIcon style={{ color: "#FFFFFF", width: "16", height: "16" }} />
                          <p>Copiar</p>
                          <Snackbar
                            open={isSnackbarOpen}
                            message="Texto copiado com sucesso!"
                            onClose={handleSnackbarClose}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.instructions}>
                      <h4>Siga as instruções abaixo:</h4>
                      <div className={styles.instructionsList}>
                        <p>1. Vá até a agência dos Correios mais próxima de você com o produto e a autorização de postagem.</p>
                        <p>2. Informe o código da autorização de postagem no balcão.</p>
                        <p>3. Envie o produto para o nosso endereço.</p>
                        <p>4. Após a avaliação, enviaremos seu vale-compras por e-mail e SMS.</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.instructions}>
                    <h4>Siga as instruções abaixo:</h4>
                    <div className={styles.instructionsList}>
                      <p>1. Vá até a agência dos Correios mais próxima de você com o produto e a autorização de postagem.</p>
                      <p>2. Pague o valor da postagem.</p>
                      <p>3. Envie o produto para {store?.name} no endereço abaixo:</p>
                      <div className={styles.return}>
                        <p>Endereço: {store?.store_return_address?.address}, {store?.store_return_address?.number}</p>
                        <p>Complemento: {store?.store_return_address?.complement}</p>
                        <p>Bairo: {store?.store_return_address?.neighborhood}</p>
                        <p>CEP: {store?.store_return_address?.zip_code}</p>
                        <p>Cidade: {store?.store_return_address?.city}</p>
                        <p>Estado: {store?.store_return_address?.province_code}</p>
                      </div>
                      <p>4. Após avaliarmos, você receberá por e-mail e SMS o seu vale-compras.</p>
                      <p>* Esta devolução está sujeita a cobrança pelos Correios.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.loading}>
            <CircularProgress style={{ color: "var(--black-default)", overflowY: "hidden" }} />
            <p>Processando a solicitação...</p>
            <div className="modal-div">
              <AddressModal
                openModal={openModal}
                loadingAddress={loadingAddress}
                address={address}
                setAddress={setAddress}
                message={message}
                createOccurrence={createOccurrence}
                loadingConfirm={loadingConfirm}
              />
            </div>
          </div>
        )}
      </Page>
    </>
  );
};

export default Post;