import React from 'react';
import { FormGroup } from "@mui/material";
import ProductItem from './ProductItem';
import styles from "../pages/Products.module.css";

const ProductList = ({
  order,
  selectedAction,
  handleSelectedAction,
  handleCredit,
  selectedReason,
  handleSelectedReason,
  getActionName,
  getOptionsForAction,
  credit,
  change,
  handleChange,
  selectedExplication,
  handleSelectedExplication,
  warrantyAction,
  handleWarrantyAction,
  optionsChange,
  optionsDevolution,
  optionsWarranty
}) => {
  return (
    <FormGroup className={styles.divgeral}>
      {order?.line_items?.map((elem) => {
        let filtered = elem.variants.filter((variant) => { return variant.id == elem.variant_id })
        filtered = filtered[0]
        let items = [];
        for (let i = 0; i < elem?.quantity; i++) {
          items.push(
            <ProductItem
              key={`${filtered?.sku}-${i}`}
              elem={elem}
              filtered={filtered}
              i={i}
              selectedAction={selectedAction}
              handleSelectedAction={handleSelectedAction}
              handleCredit={handleCredit}
              selectedReason={selectedReason}
              handleSelectedReason={handleSelectedReason}
              getActionName={getActionName}
              getOptionsForAction={getOptionsForAction}
              credit={credit}
              change={change}
              handleChange={handleChange}
              selectedExplication={selectedExplication}
              handleSelectedExplication={handleSelectedExplication}
              warrantyAction={warrantyAction}
              handleWarrantyAction={handleWarrantyAction}
              optionsChange={optionsChange}
              optionsDevolution={optionsDevolution}
              optionsWarranty={optionsWarranty}
            />
          );
        }
        return items;
      })}
    </FormGroup>
  );
};

export default ProductList;