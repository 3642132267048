import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOccurrence } from "../context/OccurrenceContext.jsx";
import Page from "../components/layout/Page.jsx";
import styles from "./Products.module.css";
import config from "../config/config.js";
import { CircularProgress } from "@mui/material";
import { Icon } from "@iconify/react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProductList from "../components/ProductList";

const Products = () => {
  const urlApi = config.api;
  const { shop, name } = useParams();
  const { store, setOccurrence, order } = useOccurrence();
  const [selectedAction, setSelectedAction] = useState(() => {
    const actions = {};
    order.line_items.forEach((item) => {
      for (let i = 0; i < item.quantity; i++) {
        actions[`${item.sku}-${i}`] = "Manter";
      }
    });
    return actions;
  });
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedExplication, setSelectedExplication] = useState({});
  const [warrantyAction, setWarrantyAction] = useState({});
  const [change, setChange] = useState({});
  const [credit, setCredit] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  
  useEffect(() => {
    const bodyHeight = document.body.offsetHeight;
    window.parent.postMessage(bodyHeight, "*");
  }, [ selectedAction, selectedReason, change, credit, message, selectedExplication ]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const optionsChange = store?.customFields?.reasonChange;
  const optionsDevolution = store?.customFields?.reasonDevolution;
  const optionsWarranty = store?.customFields?.reasonWarranty;

  const findVariantId = (product, skuId) => {
    const values = Object.values(change[skuId]);
    const titleChange = values.join(" / ");
    const selectVariant = product?.variants.find((e) => e.title === titleChange);
    return selectVariant?.id;
  };

  const getSelectedProducts = useCallback(() => {
    const entityMetafieldsDimensions = store.optionsOms?.entityMetafieldDimensions;
    return order?.line_items?.flatMap((elem) => {
      let filteredVariants = elem.variants.filter((variant) => variant.id === elem.variant_id);
      filteredVariants = filteredVariants[0];

      return Array.from({ length: elem?.quantity }, (_, i) => {
        const skuId = `${elem.sku}-${i}`;
        const action = selectedAction[skuId];

        if (action && action !== "Manter") {
          let newVariantId = null;
          if (change[skuId]) {
            newVariantId = findVariantId(elem, skuId);
          } else if (elem.variants.length === 1 && action === "Troca") {
            newVariantId = elem?.variants?.[0]?.id;
          } else if (action === "Garantia" && warrantyAction[skuId] === "Troca") {
            newVariantId = elem?.variant_id;
          }

          return {
            sku: elem.sku,
            skuId,
            name: elem.name,
            title: elem.title,
            variantTitle: elem.variant_title, // filteredVariants.title,
            action,
            reason: selectedReason[skuId],
            explication: selectedExplication[skuId],
            price: Number(elem.price),
            image: elem.image,
            newOrderVariantId: newVariantId,
            credit: credit[skuId],
            variant_id: elem.variant_id,
            product_id: elem.product_id,
            actionWarranty: warrantyAction[skuId],
            grams: elem.grams, // ????
            dimensions: {
              length: entityMetafieldsDimensions === 'variant' ? filteredVariants.length : elem.length,
              width: entityMetafieldsDimensions === 'variant' ? filteredVariants.width : elem?.width,
              height: entityMetafieldsDimensions === 'variant' ? filteredVariants.height : elem?.height,
            },
          };
        }
        return null;
      }).filter(Boolean);
    });
  }, [order, selectedAction, selectedReason, selectedExplication, warrantyAction, credit, change, findVariantId]);

  const handleSelectedAction = useCallback((sku, index, value) => {
    setSelectedAction((prev) => ({
      ...prev,
      [`${sku}-${index}`]: value,
    }));
    if (value === "Troca") {
      setCredit((prev) => ({
        ...prev,
        [`${sku}-${index}`]: false,
      }));
    }
  }, [setSelectedAction, setCredit]);

  const handleSelectedReason = useCallback((sku, index, value) => {
    setSelectedReason((prev) => ({
      ...prev,
      [`${sku}-${index}`]: value,
    }));
  }, [setSelectedReason]);

  const handleSelectedExplication = useCallback((sku, index, value) => {
    setSelectedExplication((prev) => ({
      ...prev,
      [`${sku}-${index}`]: value,
    }));
  }, [setSelectedExplication]);

  const handleWarrantyAction = useCallback((sku, index, value) => {
    setWarrantyAction((prev) => ({
      ...prev,
      [`${sku}-${index}`]: value,
    }));
  }, [setWarrantyAction]);

  const handleCredit = useCallback((sku, index, value) => {
    setCredit((prev) => ({
      ...prev,
      [`${sku}-${index}`]: value,
    }));
  }, [setCredit]);

  const handleChange = useCallback((name, sku, index, value) => {
    setChange((prev) => ({
      ...prev,
      [sku]: {
        ...prev[sku],
        [name]: value,
      },
    }));
  }, [setChange]);

  const getOptionsForAction = (action) => {
    switch (action) {
      case "Troca":
        return optionsChange;
      case "Devolução":
        return optionsDevolution;
      case "Garantia":
        return optionsWarranty;
      default:
        return [];
    }
  };

  const getActionName = (action) => {
    switch (action) {
      case "Troca":
        return "troca";
      case "Devolução":
        return "devolução";
      case "Garantia":
        return "garantia";
    }
  }

  const handleClick = () => {
    try {
      setLoadingButton(true);
      const selectedProducts = getSelectedProducts();

      if (selectedProducts.length === 0) {
        setMessage("Por favor, selecione ao menos um produto.");
        setLoadingButton(false);
        return;
      }
    
      const hasSelectedActionAndReason = selectedProducts.every((option) => {
        const hasActionAndReason = option.action && option.reason;
        if (option.action === "Troca" && !option.credit) {
          return hasActionAndReason && option.newOrderVariantId;
        } else {
          return hasActionAndReason;
        }
      });
    
      if (!hasSelectedActionAndReason) {
        setMessage("Por favor, selecione uma ação e uma razão para todos os produtos.");
        setLoadingButton(false);
        return;
      }

      const occurrenceData = {
        id: order.id,
        name: order.name?.replace("#", ""),
        idShopify: shop,
        products: selectedProducts,
        customer_id: order.customer.default_address.customer_id,
        shipping_address: order.shipping_address,
        billing_address: order.billing_address,
        shipping_reverse_free: order.shipping_reverse_free,
        carrier: order?.shipping_lines[0]?.title
      };

      setOccurrence(occurrenceData);

      const hasDevolution = selectedProducts.some(product => product.action === "Devolução" && !product.credit);
      
      if (hasDevolution && store?.bonusService?.statusBonus === 's') {
        navigate(`/${shop}/coupon/${name}`);
      } else if ((hasDevolution && !store?.bonusService?.statusBonus === 'n') || 
        selectedProducts.some((product) => product.action === "Garantia" && product.actionWarranty === 'Reembolso')) {
        navigate(`/${shop}/refund/${name}`);
      } else {
        navigate(`/${shop}/post/${name}`);
      }
      window.parent.postMessage("scroll", "*");
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      setMessage(error.response?.data?.message);
    }
  };

  return (
    <>
      <div className={styles.back}>
          <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
          <p>Trocas e Devoluções</p>
      </div>
      <Page>
        {!loading ? (
          <div className={styles.box}>
            <div className={styles.texts}>
              <h2>Lista de Produtos</h2>
              <p>Olá, {order?.customer?.first_name} {order?.customer?.last_name}!</p>
              <div className={styles.description}>
                <p>Abaixo estão todos os itens que você adquiriu nesse pedido.</p>
                <p>Selecione os produtos que deseja trocar.</p>
              </div>
            </div>
            <div className={styles.select}>
              <ProductList
                order={order}
                selectedAction={selectedAction}
                handleSelectedAction={handleSelectedAction}
                handleCredit={handleCredit}
                selectedReason={selectedReason}
                handleSelectedReason={handleSelectedReason}
                getActionName={getActionName}
                getOptionsForAction={getOptionsForAction}
                credit={credit}
                change={change}
                handleChange={handleChange}
                selectedExplication={selectedExplication}
                handleSelectedExplication={handleSelectedExplication}
                warrantyAction={warrantyAction}
                handleWarrantyAction={handleWarrantyAction}
                optionsChange={optionsChange}
                optionsDevolution={optionsDevolution}
                optionsWarranty={optionsWarranty}
              />
            </div>
            <button disabled={loadingButton} onClick={handleClick} className={styles.button}>{loadingButton ? <CircularProgress size='small' sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '12px', color: '#FFFFFF', overflow: 'hidden'}} /> : 'Continuar'}</button>
            {message && <div className={styles.message}><Icon icon="material-symbols:error" color="red" width="32" height="32" /> <p><b>{message}</b></p></div>}
          </div>
        ) : (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}
      </Page>
    </>
  );
};

export default Products;