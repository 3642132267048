import styles from "../pages/Products.module.css";

export const GenerateSelects = (options, sku, variants, change, handleChange) => {
    let response = [];
    let res = [];
    for (let elem in variants) {
      const inventory = variants[elem];
      if (inventory?.inventoryQuantity > 0) {
        res.push(inventory.title.split(" / "));
      }
    }

    let i = 0;
    const changeSku = change[sku] || [];
    res = res.filter((elem) => {
      if (changeSku) {
        if (
          (elem[0] === changeSku[0] || !changeSku[0]) &&
          (elem[1] === changeSku[1] || !changeSku[1]) &&
          (elem[2] === changeSku[2] || !changeSku[2])
        )
          return elem;
      }
    });
    for (let item in options) {
      const optionsSelect = options[item].map((opt) => {
        return (
          <option
            disabled={res.find((el) => el[i] === opt) ? false : true}
            key={opt.id}
            value={opt}
          >
            {opt} 
          </option>
        );
      });
      
      response.push(
        <div className={styles.changeSelect}>
          <p style={{ textTransform: "capitalize" }}>{item}</p>
          <div className={styles.selectContainer}>
            <select
              className={styles.standardSelect}
              name={i}
              value={change[sku]?.[i] || ""}
              required
              onChange={(e) => {
                handleChange(e.target.name, sku, i, e.target.value)
              }}
            >
              <option selected disabled value=''>
                Selecione uma opção
              </option>
              {optionsSelect} 
            </select>
            {change[sku]?.[i] && (
              <div
                className={styles.excludeButton}
                id={i}
              onClick={(e) => {
                handleChange(e.target.id, sku, i, null)
                }}
              >
                X
              </div>
            )}
          </div>
        </div>
      );

      i++;
    }

    return response;
  };