import axios from "axios";

export const handlePrice = (price) => {
    return price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const fetchStoreData = async (urlApi, shop) => {
    try {
        const aux = await (await axios.get(`${urlApi}/stores/${shop}`)).data.store;
        return aux;
    } catch (error) {
        console.error(error);
    }
};

export const getFullAction = (selectedProducts) => {
    return selectedProducts.map(elem => {
        if (elem.action && elem.credit === true) return 'Receber vale-compras'
        else if (elem.action === 'Devolução' && (elem.credit === false || elem.credit === undefined)) return 'Receber reembolso'
        else if (elem.action === 'Troca' && elem.credit === false) return 'Trocar produto'
        else if (elem.action === 'Garantia') return 'Garantia'
        else return null
    });
};

export const updateProductsForCredit = (selectedProducts) => {
    return selectedProducts.map(product => {
        if (product.action === 'Devolução' && !product.credit) {
            return {
                ...product,
                credit: true
            };
        }
        return product;
    });
};

export const states = [
    { value: "", label: "Selecione", disabled: true },
    { value: "AC", label: "AC" }, { value: "AL", label: "AL" }, { value: "AP", label: "AP" },
    { value: "AM", label: "AM" }, { value: "BA", label: "BA" }, { value: "CE", label: "CE" },
    { value: "DF", label: "DF" }, { value: "ES", label: "ES" }, { value: "GO", label: "GO" },
    { value: "MA", label: "MA" }, { value: "MT", label: "MT" }, { value: "MS", label: "MS" },
    { value: "MG", label: "MG" }, { value: "PA", label: "PA" }, { value: "PB", label: "PB" },
    { value: "PR", label: "PR" }, { value: "PE", label: "PE" }, { value: "PI", label: "PI" },
    { value: "RJ", label: "RJ" }, { value: "RN", label: "RN" }, { value: "RS", label: "RS" },
    { value: "RO", label: "RO" }, { value: "RR", label: "RR" }, { value: "SC", label: "SC" },
    { value: "SP", label: "SP" }, { value: "SE", label: "SE" }, { value: "TO", label: "TO" },
  ];