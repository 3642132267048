import Page from '../components/layout/Page.jsx';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOccurrence } from '../context/OccurrenceContext.jsx';
import { Grid } from '@mui/material';
import Resume from '../components/Resume.jsx';
import { fetchStoreData, getFullAction, updateProductsForCredit } from '../utils/utils.js';
import styles from './Coupon.module.css';
import config from '../config/config.js';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Coupon = () => {

    const urlApi = config.api
    const { shop, name } = useParams();
    const { store, occurrence, setOccurrence } = useOccurrence();
    const navigate = useNavigate();
    const [bonus, setBonus] = useState(0);

    useEffect(() => {
        const bodyHeight = document.body.offsetHeight;
        window.parent.postMessage(bodyHeight, "*");
      }, []);

    const selectedProducts = occurrence?.products

    useEffect(() => {
        const fetchData = async () => {
            const aux = await fetchStoreData(urlApi, shop)
            setBonus(aux?.bonusService?.valueBonus);
        }
        fetchData();
    }, [shop, urlApi]);

    const fullAction = getFullAction(selectedProducts)

    const handleRefundClick = () => {
        navigate(`/${shop}/refund/${name}`);
        window.parent.postMessage("scroll", "*");
    }

    const handleCreditClick = () => {
        const updatedProducts = updateProductsForCredit(selectedProducts);
        setOccurrence({
            ...occurrence,
            products: updatedProducts
        })

        navigate(`/${shop}/post/${name}`);
        window.parent.postMessage("scroll", "*");
    }

    return (
        <>
        <div className={styles.back}>
            <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
            <p>Trocas e Devoluções</p>
        </div>
        <Page>
                <div className={styles.container}>
                    <div className={styles.box}>
                        <div className={styles.boxText}>
                            <div>
                                <p>Que tal um vale-compras com <b>{bonus}% de bônus?</b></p>
                            </div>
                            <div>
                                <h2 className={styles.price}>
                                    R$ {selectedProducts.reduce((acc, elem) => {
                                        if ((elem.action === 'Devolução') && !elem.credit) {
                                            return acc + ((elem.price) + (elem.price * (bonus / 100)));
                                        } else {
                                            return acc;
                                        }
                                    }, 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h2>
                            </div>
                            <div className={styles.texts}>
                                <p>Receba um vale-compras para gastar como quiser!</p>
                                <p>É rápido e prático!</p>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <Grid container spacing={1} justifyContent='center'>
                                <Grid item xs={6}>
                                    <button className={styles.decline} onClick={handleRefundClick}>Não quero vale-compras</button>
                                </Grid>
                                <Grid item xs={6}>
                                    <button className={styles.button} onClick={handleCreditClick}>Aceito receber em vale-compras</button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Resume selectedProducts={selectedProducts} fullAction={fullAction} store={store} />
                </div>
            </Page>
        </>
    )
}

export default Coupon;