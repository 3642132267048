import { useState, useEffect, useMemo } from 'react';
import Page from "../components/layout/Page";
import { useNavigate, useParams } from 'react-router-dom';
import { useOccurrence } from '../context/OccurrenceContext';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Icon } from "@iconify/react";
import Resume from '../components/Resume.jsx';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from './Refund.module.css';
import { handlePrice, getFullAction } from '../utils/utils.js';

const Refund = () => {

    const { shop, name } = useParams()
    const { store, occurrence, setOccurrence } = useOccurrence()
    const [refundData, setRefundData] = useState({});
    const [paymentOrder, setPaymentOrder] = useState(0)
    const [isCheck, setIsCheck] = useState(false)
    const [message, setMessage] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        const bodyHeight = document.body.offsetHeight;
        window.parent.postMessage(bodyHeight, "*");
    }, [refundData]);

    useEffect(() => {
        if (message) {
          const timer = setTimeout(() => setMessage(""), 5000);
          return () => clearTimeout(timer);
        }
      }, [message]);

    const selectedProducts = occurrence?.products

    const returnOptions = useMemo(() => [
        { id: 0, label: 'Selecione', value: '' },
        { id: 1, label: 'PIX', value: 'pix' },
        { id: 2, label: 'Transferência Bancária', value: 'ted' }
    ], []);

    const pixTypeOptions = useMemo(() => [
        { id: 0, label: 'Selecione', value: '' },
        { id: 1, label: 'CPF/CNPJ', value: 'cpf' },
        { id: 2, label: 'Número de celular', value: 'phone' },
        { id: 3, label: 'Email', value: 'email' },
        { id: 4, label: 'Chave aleatória', value: 'random' }
    ], []);

    const paymentOptions = useMemo(() => [
        { id: 0, label: 'Selecione', value: '' },
        { id: 1, label: 'Cartão de crédito', value: 'creditCard' },
        { id: 2, label: 'PIX', value: 'pix' },
        { id: 3, label: 'Boleto', value: 'invoice' },
        { id: 4, label: 'Outros', value: 'others' }
    ], []);

    const handleRefund = (e, value) => {
        if ((value === 'agency' && e.target.value.length > 4) || (value === 'account' && e.target.value.length > 5)) {
            return null
        }
        setRefundData(prevState => ({
            ...prevState,
            [value]: e.target.value
        }))
    }

    const fullAction = getFullAction(selectedProducts)

    const handleClick = () => {
        if (!paymentOrder) {
            setMessage('Selecione uma forma de pagamento')
            return
        }
        
        if (paymentOrder !== 'creditCard') {
            if ((paymentOrder === 'pix' && (!refundData.holder || !refundData.keyType || !refundData.pixKey)) || 
            (paymentOrder === 'ted' && (!refundData.holder || !refundData.cpf || !refundData.bank || !refundData.agency || !refundData.account))) {
                setMessage('Preencha todos os campos obrigatórios')
                return
            }
        }

        if (!isCheck) {
            setMessage('Você precisa concordar com os termos para continuar')
            return
        }

        setOccurrence({
            ...occurrence,
            paymentOrder,
            refund: refundData
        })
        
        navigate(`/${shop}/post/${name}`);
        window.parent.postMessage("scroll", "*");
    }

    return (
        <>
            <div className={styles.back}>
                <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
                <p>Trocas e Devoluções</p>
            </div>
            <Page>
                <div className={styles.container}>
                    <div className={styles.box}>
                        <div className={styles.texts}>
                            <div>
                                <h2>Informações do reembolso</h2>
                            </div>
                            <div>
                                <p>Preencha as informações abaixo para processarmos o seu reembolso.</p>
                                <p>Certifique-se de que os dados estão corretos.</p>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth className={styles.formControl}>
                                    <p>Qual foi a forma de pagamento da sua compra?</p>
                                    <select
                                        id='payment'
                                        value={paymentOrder}
                                        className={styles.select}
                                        onChange={(e) => setPaymentOrder(e.target.value)}
                                        required
                                        >
                                            {paymentOptions.map((item) => (
                                                <option value={item.value} key={item.id}>
                                                    {item.label}
                                                </option>
                                            ))}
                                    </select>
                                </FormControl>
                            </Grid>
                            {(paymentOrder === 'pix' || paymentOrder === 'invoice' || paymentOrder === 'others') && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth className={styles.formControl}>
                                        <p>Forma de reembolso</p>
                                        <select
                                            id='payment'
                                            value={refundData.paymentType}
                                            className={styles.select}
                                            onChange={(e) => handleRefund(e, 'paymentType')}
                                            required
                                        >
                                            {returnOptions.map((item) => (
                                                <option value={item.value} key={item.id}>
                                                        {item.label}
                                                </option>
                                                ))}
                                        </select>
                                    </FormControl>
                                </Grid>
                            )}
                            {(refundData.paymentType === 'pix' && paymentOrder !== 'creditCard') && (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Titular da conta</p>
                                            <input required className={styles.input} value={refundData.holder || ''} onChange={(e) => handleRefund(e, 'holder')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Tipo de chave</p>
                                            <select
                                                id='keyType'
                                                value={refundData.keyType}
                                                className={styles.select}
                                                onChange={(e) => handleRefund(e, 'keyType')}
                                                required
                                            >
                                                {pixTypeOptions.map((item) => (
                                                    <option value={item.value} key={item.id}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Chave</p>
                                            <input required className={styles.input} value={refundData.pixKey || ''} onChange={(e) => handleRefund(e, 'pixKey')} />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {(refundData.paymentType === 'ted' && paymentOrder !== 'creditCard') && (
                                <>
                                    <Grid item xs={12} >
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Titular da conta</p>
                                            <input required className={styles.input} value={refundData.holder || ''} onChange={(e) => handleRefund(e, 'holder')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>CPF ou CNPJ</p>
                                            <input required className={styles.input} value={refundData.cpf || ''} onChange={(e) => handleRefund(e, 'cpf')} type='number' />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Banco</p>
                                            <input required className={styles.input} value={refundData.bank || ''} onChange={(e) => handleRefund(e, 'bank')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth className={styles.formControl}>  
                                            <p>Agência (sem dígito verificador)</p>
                                            <input required className={styles.input} value={refundData.agency || ''}  onChange={(e) => handleRefund(e, 'agency')} type='number' />  
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth className={styles.formControl}>
                                            <p>Conta corrente (sem dígito verificador)</p>
                                            <input required className={styles.input} value={refundData.account || ''} onChange={(e) => handleRefund(e, 'account')} type='number' />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {paymentOrder === 'creditCard' && (
                                <div className={styles.creditMessage}>
                                    <p>Após avaliação do produto, sua compra será estornada.</p>
                                </div>
                            )}
                            <div className={styles.terms}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox style={{ color: "var(--black-default)", '&.MuiChecked': {color: "var(--black-default)"}}} onChange={(e) => setIsCheck(e.target.checked)} />} label={<div className={styles.termsText}><p>Li e concordo com os <a href='/'>termos</a>.</p></div>} />
                                </FormGroup>
                            </div>
                        </Grid>
                        <button className={styles.button} onClick={handleClick}>Confirmar</button>
                        {message && <div className={styles.message}><Icon icon="material-symbols:error" color="red" width="32" height="32" /> <p><b>{message}</b></p></div>}
                    </div>
                    <Resume selectedProducts={selectedProducts} fullAction={fullAction} store={store} />
                </div>
            </Page>
        </>
    )
}

export default Refund;