import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOccurrence } from "../context/OccurrenceContext.jsx";
import Page from "../components/layout/Page.jsx";
import config from "../config/config.js";
import axios from "axios";
import styles from "./Initial.module.css";
import { CircularProgress, Modal, Box, Button } from "@mui/material";
import { Icon } from "@iconify/react";


const Initial = () => {
  const urlApi = config.api;
  const { shop } = useParams();
  const { store, setOrder } = useOccurrence()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    const bodyHeight = document.body.offsetHeight;
    window.parent.postMessage(bodyHeight, "*");
  }, [message]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const deleteOccurrence = async () => {
    try {
      let aux = name.includes("#") ? name.split("#")[1] : name;
      aux = aux.toString();
      await axios.delete(
        `${urlApi}/delete-occurrence/${shop}/${aux}`
      );
      setLoading(true);
      await handleFormSubmit();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
      setMessage(error?.response?.data.message || "");
    }
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      if (!name || !email) {
        setMessage("Preencha os campos obrigatórios");
        return;
      }

      const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!emailRegex.test(email)) {
        setMessage("E-mail inválido");
        return;
      }

      let aux = name.includes("#") ? name.split("#")[1] : name;
      aux = aux.toString();
      const res = await axios.get(`${urlApi}/theme-extension/${shop}/${aux}/${email}`);
      setOrder(res?.data.order);

      navigate(`/${shop}/products/${aux}/${email}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error?.response?.status === 422) {
        setMessage("Ocorrência em andamento para este pedido.");
        return ;
      }
      if (error?.response?.status === 409) {
        handleOpen();
        setMessage(error?.response?.data?.message || "");
        return ;
      }     
      setMessage(error?.response?.data?.message || "");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === "13") {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  return (
    <>
    <Page>
            <div className={styles.box}>
              {!store?.initialMessages && (
                <div className={styles.texts}>
                    <h2>Buscar pedido</h2>
                    <p><b>Simplicamos as suas trocas!</b></p>
                    <p>Em apenas alguns passos, você poderá solicitar a troca de um produto. 
                    Insira o número do seu pedido para que possamos identificar os itens desejados.</p>
                </div>
              )}
                <div className={styles.div_field}>
                    <div>
                        <label htmlFor='id'>Número do pedido</label>
                        <input className={styles.input} id='id' type='text' placeholder='Digite o número do pedido' onChange={(e) => setName(e.target.value)} onKeyDown={handleKeyDown} />
                    </div>
                    <div>
                        <label htmlFor='email'>E-mail</label>
                        <input className={styles.input} id='email' type='text' placeholder='Digite seu e-mail' onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} />
                    </div>
                </div>

                <button disabled={loading} onClick={handleFormSubmit} className={styles.button}>{loading ? <CircularProgress size='small' sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '12px', color: '#FFFFFF', overflow: 'hidden'}} /> : 'Continuar'}</button>

                {message && <div className={styles.message}><Icon icon="material-symbols:error" color="red" width="32" height="32" /> <p><b>{message}</b></p></div>}
            </div>
        </Page>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
             width: 400, bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
             borderRadius: '4px'  }}>
            <p id="modal-modal-title" variant="h6" component="h2">
              Já existe uma ocorrência para este pedido.
            </p>
            <p id="modal-modal-description" sx={{ mt: 2 }}>
              Deja excluir a ocorrência antiga?
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px', gap: '8px'}}>
              <Button disabled={loading} sx={{ textTransform: 'capitalize', backgroundColor: 'var(--green-100)', color: 'var(--white-100)', 
                '&:hover': { transition: '0.2s', backgroundColor: 'var(--green-100)'}}} onClick={deleteOccurrence}>Excluir</Button>
              <Button disabled={loading} sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleClose}>Fechar</Button>
            </div>
          </Box>
      </Modal>
    </>
  );
};

export default Initial;