import { createContext, useContext, useState, useEffect } from 'react';
import config from '../config/config.js';
import { fetchStoreData } from '../utils/utils.js';

export const OccurrenceContext = createContext()

export function OccurrenceProvider({ children }) {
    const urlApi = config.api;
    const url = new URL(window.location.href);
    const shop = url.pathname.substring(1).split('/')[0]
    const [occurrence, setOccurrence] = useState()
    const [order, setOrder] = useState();
    const [store, setStore] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const aux = await fetchStoreData(urlApi, shop);
            setStore(aux);
        };
        fetchData();
    }, [shop, urlApi])

    return (
        <OccurrenceContext.Provider value={{ occurrence, setOccurrence, order, setOrder, store, setStore }}>
            {children}
        </OccurrenceContext.Provider>
    )
}

export function useOccurrence() {
    const context = useContext(OccurrenceContext)
    return context
}