import { Icon } from '@iconify/react';
import { Chip } from '@mui/material';
import styles from '../pages/Coupon.module.css';
import { handlePrice, getFullAction } from '../utils/utils.js';

const Resume = ({selectedProducts, fullAction, store }) => {
    const bonus = store?.bonusService.valueBonus
    return (
        <div className={styles.boxResume}>
                        <div className={styles.titleResume}>
                            <p>Resumo da Solicitação</p>
                        </div>
            {selectedProducts.map((elem, index) => {
                return (
                    <div key={index} className={styles.item}>
                        <div className={styles.basicResume}>
                            {elem?.src ? <img src={elem.src} alt={elem.name} /> : <Icon icon="icon-park-outline:ad-product" color="grey" width="75" height="82" style={{ padding: '8px', marginRight: '20px' }} />}
                            <p>{elem.name}</p>
                        </div>
                        <div className={styles.priceResume}>
                        <div className={styles.priceContainer}>
                            <div>
                                <p>Preço unitário:</p>
                            </div>
                            <div className={styles.value}>
                                <p>R$ {handlePrice(elem.price)}</p>
                            </div>
                        </div>
                    {(elem.action === 'Devolução' && !elem.credit && store.bonusService.statusBonus === 's') && (
                        <div className={styles.bonusContainer}>
                            <div>
                                <p>Preço bonificado:</p>
                                                </div>
                        <div className={styles.bonus}>
                            <Chip label='Bônus' sx={{ backgroundColor: '#10B981', color: 'white' }} />
                            <p> R$ {handlePrice(elem.price + ((elem.price * bonus) / 100))}</p>
                        </div>
                        </div>
                    )}
                    <div className={styles.actionContainer}>
                        <div>
                            <p>Ação:</p>
                        </div>
                        <div className={styles.value}>
                            <p>{fullAction[index]}</p>
                        </div>
                    </div>
                </div>
                    {selectedProducts.length > 1 && index < selectedProducts.length - 1 && (
                        <div>
                            <hr />
                        </div>
                    )}
                </div>
            )
        })}
        </div>
    )
}

export default Resume;