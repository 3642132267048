import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, TextField, FormControl, Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import styles from "../pages/Products.module.css";
import { useOccurrence } from '../context/OccurrenceContext';
import { GenerateSelects } from './GenerateSelects.jsx';

const ProductItem = ({
  elem,
  filtered,
  i,
  selectedAction,
  handleSelectedAction,
  handleCredit,
  selectedReason,
  handleSelectedReason,
  getActionName,
  getOptionsForAction,
  credit,
  change,
  handleChange,
  selectedExplication,
  handleSelectedExplication,
  warrantyAction,
  handleWarrantyAction,
  optionsChange,
  optionsDevolution,
  optionsWarranty
}) => {
  const { order } = useOccurrence()
  return (
    <div key={`${filtered?.sku}-${i}`} className={styles.option}>
      <div key={filtered?.id} className={styles.products}>
        <div className={styles.item}>
          <div className={styles.img}>
            {elem?.image ? (
              <img src={elem?.image} alt="img" />
            ) : (
              <Icon
                icon="icon-park-outline:ad-product"
                color="grey"
                width="60"
                height="72"
                style={{ padding: "8px", marginRight: "20px" }}
              />
            )}
          </div>
          <div className={styles.productInfo}>
            <p>{elem?.variant_title || elem?.title || "Default Title"}</p>
            {elem?.hasWarranty && (
              <div>
                <Chip label="Em garantia" className={styles.chip} style={{ backgroundColor: "var(--black-button)", color: "var(--white-100)" }} size="small" />
              </div>
            )}
            <p>R$ {elem?.price}</p>
          </div>

          <div className={styles.action}>
            <select
              id="action"
              defaultValue={"Manter"}
              className={styles.actionSelect}
              value={selectedAction[`${filtered?.sku}-${i}`]}
              onChange={(e) => {
                handleSelectedAction(filtered?.sku, i, e.target.value)
                handleCredit(filtered?.sku, i, false)
              }}
            >
              <option value="Manter">Manter item</option>
              {(!order.deadLineDays && optionsChange?.length > 0) && (
                <option value="Troca">Trocar item</option>
              )}
              {(order.hasRefund && optionsDevolution?.length > 0) && (
                <option value="Devolução">
                  Devolver item
                </option>
              )}
              {(elem.hasWarranty && optionsWarranty?.length > 0) && (
                <option value="Garantia">
                  Acionar garantia
                </option>
              )}
            </select>
          </div>
        </div>
      </div>

      {selectedAction[`${filtered?.sku}-${i}`] && selectedAction[`${filtered?.sku}-${i}`] !== "Manter" && (
        <div className={styles.additional}>
          <div className={styles.motive}>
            <p>Selecione o motivo da {getActionName(selectedAction[`${filtered?.sku}-${i}`])}</p>
            <FormControl>
              <select
                value={selectedReason[`${filtered?.sku}-${i}`] || ""}
                required
                className={styles.standardSelect}
                onChange={(e) => handleSelectedReason(filtered?.sku, i, e.target.value)}
              >
                <option value="" disabled>
                  Selecione uma opção...
                </option>
                {getOptionsForAction(selectedAction[`${filtered?.sku}-${i}`]).map((item) => (
                  <option key={item.id} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>

          {selectedAction[`${filtered?.sku}-${i}`] === "Troca" &&
            selectedReason[`${filtered?.sku}-${i}`] &&
            !credit[`${filtered?.sku}-${i}`] &&
            elem?.variants?.length > 1 && (
              <div className={styles.change}>
                <p>Escolha por qual produto você deseja trocar</p>
                {GenerateSelects(elem.options, `${filtered?.sku}-${i}`,
                  elem.variants, change, handleChange)}
              </div>
            )}
          <div className={styles.checkBoxComments}>
            <FormGroup>
              {selectedReason[`${filtered?.sku}-${i}`] &&  
                selectedAction[`${filtered?.sku}-${i}`] ===
                "Troca" && (
                  <div className={styles.helperText}>
                    <p>Se não encontrou a opção desejada, selecione a alternativa abaixo.</p>
                  </div>
                )}
              {selectedAction[`${filtered?.sku}-${i}`] !== "Garantia" ? (
                <FormControlLabel
                  control={
                    <Checkbox style={{ color: "var(--black-default)", '&.MuiChecked': {color: "var(--black-default)"}}}/>
                  }
                  label={<p className={styles.labelCheck}>Receber vale-presente para trocar por outro produto.</p>}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleCredit(filtered?.sku, i, true);
                    } else {
                      handleCredit(filtered?.sku, i, false)
                    }
                  }}
                />
              ) : (
                <div className={styles.motive}>
                  <p>O que deseja da garantia</p>
                  <select
                    value={
                      warrantyAction[`${filtered?.sku}-${i}`] ||
                      ""
                    }
                    required
                    className={styles.standardSelect}
                    onChange={(e) => handleWarrantyAction(filtered?.sku, i, e.target.value)}
                  >
                    {optionsChange?.length > 0 && (
                      <option key={"troca"} value={"Troca"}>Troca</option>
                    )}
                    <option key={"reparo"} value={"Reparo"}>Reparo</option>
                    <option key={"reembolso"} value={"Reembolso"}>Reembolso</option>
                  </select>
                </div>
              )}
            </FormGroup>
            <div className={styles.explication}>
              <p>Feedback (Opcional)</p>
              <label htmlFor="explication">Como podemos melhorar sua experiência?</label>
              <TextField
                fullWidth
                id="explication"
                variant="outlined"
                value={selectedExplication[`${filtered?.sku}-${i}`]}
                onChange={(e) => handleSelectedExplication(filtered?.sku, i, e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductItem;