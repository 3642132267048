import { Modal, Box, Grid, CircularProgress } from '@mui/material';
import styles from '../pages/Post.module.css';
import { states } from '../utils/utils.js';

const AddressModal = ({ openModal, loadingAddress, address, setAddress, message, createOccurrence, loadingConfirm }) => {
  return (
        <Modal
              open={openModal}
              hideBackdrop
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: '#FFFFFF', width: '70%', maxWidth: 500, border: '2px solid #000', borderRadius: '8px', p: 5 }}>
                {loadingAddress ? <div className={styles.loading}><CircularProgress style={{ color: "var(--black-default)"}}/></div> : 
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <label className={styles.label} htmlFor="address-street">Rua</label>
                        <input className={styles.input} id="address-street" value={address.street} onChange={(e) => setAddress({...address, street: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-number">Número</label>
                      <input className={styles.input} id="address-number" value={address.number} onChange={(e) => setAddress({...address, number: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-complement">Complemento</label>
                      <input className={styles.input} id="address-complement" value={address.complement} onChange={(e) => setAddress({...address, complement: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-neighborhood">Bairro</label>
                      <input className={styles.input} id="address-neighborhood" value={address.neighborhood} onChange={(e) => setAddress({...address, neighborhood: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-zip">CEP</label>
                      <input className={styles.input} id="address-zip" value={address.zip} onChange={(e) => setAddress({...address, zip: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-city">Cidade</label>
                      <input className={styles.input} id="address-city" value={address.city} onChange={(e) => setAddress({...address, city: e.target.value})} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <label className={styles.label} htmlFor="address-uf">UF</label>
                      <select className={styles.standardSelect} id="address-uf" value={address.province} onChange={(e) => setAddress({...address, province: e.target.value})}>
                        {states.map((state) => (
                          <option key={state.value} disabled={state.disabled} value={state.value}>{state.label}</option>
                        ))}
                      </select>
                    </Grid>
                    {message && (
                      <Grid item xs={12} sm={12}>
                        <p style={{color: 'red'}}>{message}</p>
                      </Grid>
                    )}
                    <Grid item xs={4} sm={12}>
                      <button className={styles.button} onClick={() => {createOccurrence()}} disabled={loadingConfirm}>{loadingConfirm ? <CircularProgress size={20} style={{ color: "var(--white-100)"}}/> : 'Continuar'}</button>
                    </Grid>
                </Grid>
                }
              </Box>
            </Modal>
    )
}                            

export default AddressModal;